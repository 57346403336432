import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
function Discover3() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Row className=" mt-2 mb-5">
            <Col md="12" className="text-center">
              <h3 className="logo-txt-color">DISCOVER AGROROUTE</h3>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  KEY FOCUS AREAS
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  The focus areas of Agroroute Nepal in relation to farmer
                  organizations, cooperatives, and small medium enterprises are
                  the following:
                  <br />
                  <br />
                  <li>
                    Strengthening governance and financial management of farmer
                    organizations, cooperatives, and small medium enterprises
                  </li>
                  <li>Business Development Services (BDS)</li>
                  <li>Marketing</li>
                  <li>Value Chain Development</li>
                  <li>
                    Lobbying and advocacy (review/research of existing legal
                    provisions
                  </li>
                  <br />
                  Consolidating its proven results and envisioning a
                  transformation in the lives of rural population who are
                  organised within the cooperatives, Agroroute Nepal intends to
                  work by focusing on the following three key areas in order to
                  enhance cooperative development in Nepal.
                  <br />
                  <Link className="links" to="/cbd">
                    <li> Cooperative Business Development</li>
                  </Link>
                  <Link className="links" to="/spp">
                    <li>Sustainable Production and Productivity</li>
                  </Link>
                  <Link className="links" to="/occ">
                    <li>Organisational Capacity of Cooperatives</li>
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default Discover3;
