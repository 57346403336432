import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/NavBar";
import { Row, Col, Card, ListGroup } from "react-bootstrap";
import img1 from "../Assets/Results1.jpg";
import img2 from "../Assets/Results2.jpg";
import chart from "../Assets/chart.png";

function Results() {
  return( 
  <Container fluid>
    <NavBar/>
    <Container className="my-4 p-3 bg-light" fluid>
      <Row className="p-3">
        <Col className="text-center logo-txt-color" md={12}>
          <h3>RESULTS</h3>
        </Col>
      </Row>

      <Row>

      </Row>

      <Row>
      <Col className="" md={{ span: 8, offset: 2 }} align="justified">
            <Card className="my-2 p-1 rounded">
              <Card.Body>
                <Card.Header><h4 className="logo-txt-color1">Facilitation to export tea to Netherlands</h4></Card.Header>
                <Card.Text className="text-center mt-2 mb-3 p-1" align="justified">
                Tinjure Tea Farmers Cooperative Society Ltd. (TTFCSL) is a tea farmers primary
cooperative (PC) located in Tinjure village, approximately 100 km driving North-West
from Ilam city. The organization is specialized in the collection, processing, and
marketing of the fresh tea produced by their small-scale member farmers. 
Agroroute has facilitated TTFCSL to export tea to MAAS international, a Netherlands based organization. With the export, the cooperative gained 4-5 times higher margin compared to selling to local or Indian buyers. Similarly, MAAS supports local communities and female empowerment. Furthermore, the cooperative receives support in certification, training, knowledge, export from MAAS. 

                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
      </Row>
      <Row></Row>
      <Row>
      <Col md={{span:4,offset:2}}>
              <Row className="p-2 mt-3 mb-3">
                <Card className="p-4 img-fluid rounded">
                  <Card.Img
                    height="400"
                    variant="top"
                    src={img1}
                  />
                </Card>
              </Row>
            </Col>
            <Col md="4">
              <Row className="p-2 mt-3 mb-3">
                <Card className="p-4 img-fluid rounded">
                  <Card.Img
                    height="400"
                    variant="top"
                    src={img2}
                  />
                </Card>
              </Row>
            </Col>
      </Row>

      <Row></Row>
      
      <Row>
      <Col className="" md={{ span: 8, offset: 2 }} align="justified">
            <Card className="my-2 p-1 rounded">
              <Card.Body>
                <Card.Header><h4 className="logo-txt-color1">Increase in internal capitalization- a case study of Shree Dumarwana Milk Producer Cooperative Limited</h4></Card.Header>
                <Card.Text className="text-center mt-2 mb-3 p-1" align="justified">
                <h6>Internal capital is a major part of the foundation of the cooperative society</h6>
                <h6>Farmers who own and finance the cooperative are those that use it.</h6>
                </Card.Text>
                <Card.Header><h6 className="logo-txt-color1">Why internal capital is important?</h6></Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item><h6>Trust:</h6> Proofs that the society is adding value</ListGroup.Item>
                  <ListGroup.Item><h6>Economic activity:</h6> Indicates that the activity is profitable</ListGroup.Item>
                  <ListGroup.Item><h6>Buffer:</h6> Indicates that the cooperative can deal with disappointments</ListGroup.Item>
                  <ListGroup.Item><h6>Sustainable:</h6> Shows that the cooperative is working for future development</ListGroup.Item>
                  <ListGroup.Item><h6>Cost effective:</h6> Internal capital costs less than external capital</ListGroup.Item>
                  <ListGroup.Item><h6>Equity:</h6> Leverage for external financing</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
      </Row>
      <Row></Row>
      <Row>
      <Col className="" md={{ span: 8, offset: 2 }} align="justified">
            <Card className="my-2 p-1 rounded">
              <Card.Body>
                <Card.Header><h4 className="logo-txt-color1">Facilitation to export tea to Netherlands</h4></Card.Header>
                <Card.Text className="text-center mt-2 mb-3 p-1" align="justified">
                <p align="justified">Shree Dumarwana Milk Producer Cooperative Limited (SDMPCL) could be a good example who really absorbed the advice on the importance of internal capitalization from Agriterra. SDMPCL, located in the central south of Nepal, was established to add the value of the milk produced by the smallholder farmers. Agriterra has been collaborating with SDMPCL since 2017 in order to professionalize the cooperative and to strive towards its goal to improve profitability for the farmers.</p>
<p align="justified">The audit report of 5 years shows the share capital increased (presented in graph below) by almost triple in 5 years period. This is happened only because of the farmers' contribution to the cooperative. The farmers had not directly deposited money at once to the cooperative, however, the cooperative deducted Nrs 1 based on the litre of the milk supply to the cooperative. The cooperative started implementing it from the third quarter of 2018.</p>
<p align="justified">The cooperative utilized the increased share capital by investing in a dairy processing plant which helps to add value to the milk. The government supported the cooperative by a grant for the installation of the processing plant where the cooperative utilized this increased capital as a matching fund. After the installation of the processing plant, the income of the cooperative increased by NRs 32 million compared to last year income (NRs 258 million). Currently, the cooperative produces pasteurized milk, yoghurt, paneer (cottage cheese), butter and ghee.</p>
 

                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
      </Row>
      <Row>

      </Row>

      <Row>
      <Col md={{span:6,offset:3}}>
              <Row className="p-2 mt-3 mb-3">
                <Card className="p-4 img-fluid rounded">
                  <Card.Img
                    height="500"
                    variant="top"
                    src={chart}
                  />
                </Card>
              </Row>
            </Col>
      </Row>

    </Container>
    <Footer/>
  </Container>
  )
}

export default Results;
