import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import video from "../Media/video-agriterra.mp4";

function Home() {
  return (
    <Container className="mt-5" fluid>
      <Row>
        <Col md="12">
          <video className="videodemo" controls autoplay>
            <source src={video} type="video/mp4" />
          </video>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
