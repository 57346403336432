import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/NavBar";
import { Row, Col, Card } from "react-bootstrap";
import yanamani from "../Assets/yanamani.jpg";
import rijan from "../Assets/rijan.jpg";
import swechchha from "../Assets/swechchha.jpg";
import trijan from "../Assets/trijan.jpg";
import sajena from "../Assets/sajena.jpg";

function Teams() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="mt-2 bg-light" fluid>
        <Row className="p-3">
          <Row>
            <Col md="12" className="text-center">
              <h3 className="logo-txt-color">Our Team</h3>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Row className="mt-3 mb-3 mx-5">
                <Card>
                  <Card.Img className="teamcards" variant="top" src={trijan} />
                </Card>
                <Card className="txt-bg">
                  <Card.Title className="text-center">Trijan Singh</Card.Title>
                  <Card.Link
                    class="teamdata"
                    href="https://www.linkedin.com/in/trijan-singh-9ba36251/"
                  >
                    LinkedIn
                  </Card.Link>
                  <Card.Link class="teamdata" href="tel:+977-9841350142">
                    WhatsApp: +977-9841350142
                  </Card.Link>
                  <Card.Link
                    class="teamdata"
                    href="mailto:trijan.singh@agroroutenepal.org"
                  >
                    trijan.singh@agroroutenepal.org
                  </Card.Link>
                </Card>
              </Row>
            </Col>
            <Col md="4">
              <Row className="mt-3 mb-3 mx-5">
                <Card>
                  <Card.Img
                    className="teamcards"
                    variant="top"
                    src={yanamani}
                  />
                </Card>
                <Card className="txt-bg">
                  <Card.Title className="text-center">
                    Yanamani Nepal
                  </Card.Title>
                  <Card.Link
                    class="teamdata"
                    href="https://www.linkedin.com/in/yanamani-nepal-468a59130/"
                  >
                    LinkedIn
                  </Card.Link>
                  <Card.Link class="teamdata" href="tel:+977-9841976172">
                    WhatsApp: +977-9841976172
                  </Card.Link>
                  <Card.Link
                    class="teamdata"
                    href="mailto:yanamani.nepal@agroroutenepal.org"
                  >
                    yanamani.nepal@agroroutenepal.org
                  </Card.Link>
                </Card>
              </Row>
            </Col>
            <Col md="4">
              <Row className="mt-3 mb-3 mx-5">
                <Card>
                  <Card.Img className="teamcards" variant="top" src={rijan} />
                </Card>
                <Card className="txt-bg">
                  <Card.Title className="text-center">Rijan Ojha</Card.Title>
                  <Card.Link
                    class="teamdata"
                    href="https://www.linkedin.com/in/rijan-ojha-280b11ab/"
                  >
                    LinkedIn
                  </Card.Link>
                  <Card.Link class="teamdata" href="tel:+977-980108945">
                    WhatsApp: +977-980108945
                  </Card.Link>
                  <Card.Link
                    class="teamdata"
                    href="mailto:ojha@agroroutenepal.org"
                  >
                    ojha@agroroutenepal.org
                  </Card.Link>
                </Card>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <Row className="mt-3 mb-3 mx-5">
                <Card>
                  <Card.Img className="teamcards" variant="top" src={sajena} />
                </Card>
                <Card className="txt-bg">
                  <Card.Title className="text-center">Sajena Dwa</Card.Title>
                  <Card.Link
                    class="teamdata"
                    href="https://www.linkedin.com/in/sajena-dwa-2bb199bb/"
                  >
                    LinkedIn
                  </Card.Link>
                  <Card.Link class="teamdata" href="tel:+977-9846784924">
                    WhatsApp: +977-9846784924
                  </Card.Link>
                  <Card.Link
                    class="teamdata"
                    href="mailto:sajena.dwa@agroroutenepal.org"
                  >
                    sajena.dwa@agroroutenepal.org
                  </Card.Link>
                </Card>
              </Row>
            </Col>
          </Row>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default Teams;
