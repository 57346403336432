import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { file, url } from "../../url";

function News() {
  const [newsItems, setnewsItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${url}/news`);
        setnewsItems(res.data);
      } catch (err) {}
    };
    fetchData();
  }, []);
  console.log(newsItems);
  return (
    <Container className="my-5 bg-light" fluid>
      <Row className="p-3">
        <Row className=" mt-5">
          <Col md="12" className="text-center">
            <h3 className="logo-txt-color">LATEST NEWS</h3>
          </Col>
        </Row>
        <Row>
          {newsItems.map(
            (newsItem, index) =>
              index < 3 && (
                <Col md="4" className=" mt-3 mb-3">
                  <Card className="p-4 img-fluid">
                    <Card.Img
                      height="300"
                      variant="top"
                      src={file + newsItem.thumbnail}
                    />
                    <Card.Body>
                      <Card.Title>{newsItem.title}</Card.Title>
                      <Card.Text>{newsItem.excerpt}</Card.Text>
                    </Card.Body>
                    <NavLink to={`news/${newsItem.id}`}>
                      <Button className="w-100" variant="secondary">
                        View in Detail
                      </Button>
                    </NavLink>
                  </Card>
                </Col>
              )
          )}
        </Row>
      </Row>
    </Container>
  );
}

export default News;
