import React from "react";
import { Card, CardGroup } from "react-bootstrap";
import logo from "../Media/AGROROUTE.png";
import CallIcon from "@material-ui/icons/Call";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <CardGroup>
        <Card className="footerBg ">
          <Card.Body>
            <Card.Img variant="top" src={logo} />
          </Card.Body>
        </Card>
        <Card className="footerBg">
          <Card.Body>
            <Card.Title className="textColor">Address</Card.Title>
            <p>
              2nd Floor
              <br /> Oasis building <br /> Patan Dhoka, Lalitpur <br /> Nepal
            </p>
            <p>
              <EmailOutlinedIcon fontSize="small" />
              agroroute@agroroutenepal.org
            </p>
            <p></p>
          </Card.Body>
        </Card>
        <Card className="footerBg">
          <Card.Body>
            <Card.Title className="textColor">Agroroute</Card.Title>
            <p>
              <ArrowForwardIosOutlinedIcon fontSize="small" />
              <Link to="/discover">Our vision & approach</Link>
            </p>
          </Card.Body>
        </Card>
        <Card className="footerBg">
          <Card.Body>
            <Card.Title className="textColor">Cooperatives</Card.Title>
            <p>
              Cooperatives and commodities <br /> Factsheets <br /> Cooperative
              businesses
            </p>
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
};

export default Footer;
