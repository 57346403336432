import React from "react";
import { Route, Switch } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";

import HomePage from "./Pages/HomePage";
import NewsPage from "./Pages/NewsPage";
import Results from "./Pages/Results";
import Training from "./Pages/Training";
import Teams from "./Pages/Teams";
import Discover from "./Pages/Discover";
import ClientNetwork from "./Pages/Clients_Networks";
import AllNews from "./Pages/AllNews";
import Discover2 from "./Pages/Discover2";
import Discover3 from "./Pages/Discover3";
import CBD from "./Pages/CBD";
import SPP from "./Pages/SPP";
import OCC from "./Pages/OCC";
import Discover4 from "./Pages/Discover4";
import Discover5 from "./Pages/Discover5";

function RoutePaths() {
  return (
    <Switch>
      <Route path="/" component={HomePage} exact />
      <Route path="/news/:id" component={NewsPage} exact />
      <Route path="/training" component={Training} exact />
      <Route path="/about_us" component={AboutUs} exact />
      <Route path="/results" component={Results} exact />
      <Route path="/team" component={Teams} exact />
      <Route path="/discover" component={Discover} />
      <Route path="/discover2" component={Discover2} />
      <Route path="/discover3" component={Discover3} />
      <Route path="/discover4" component={Discover4} />
      <Route path="/discover5" component={Discover5} />

      <Route path="/cbd" component={CBD} />
      <Route path="/spp" component={SPP} />
      <Route path="/occ" component={OCC} />

      <Route path="/clientNetwork" component={ClientNetwork} />
      <Route path="/allnews" component={AllNews} />
    </Switch>
  );
}

export default RoutePaths;
