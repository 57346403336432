import { Router } from "react-router-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import "./App.css";
import RoutePaths from "./Routes";

function App() {
  return (
    <BrowserRouter>
      <RoutePaths />
    </BrowserRouter>
  );
}

export default App;
