import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Home from "../Components/Home/Home";
import News from "../Components/News/News";
import Gallery from "../Components/Gallery/Gallery";
import Footer from "../Components/Footer/Footer";
import { Container } from "react-bootstrap";

function HomePage() {
  return (
    <Container fluid>
      <NavBar />
      <Home />
      <News />
      <Gallery />
      <Footer />
    </Container>
  );
}

export default HomePage;
