import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";

function CBD() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Row className=" mt-2 mb-5">
            <Col md="12" className="text-center">
              <h3 className="logo-txt-color">DISCOVER AGROROUTE</h3>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-2 logo-txt-color ">
                  COOPERATIVE BUSINESS DEVELOPMENT
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  Cooperative business development is the soul of Agroroute
                  Nepal. It believes that well organised farmers and
                  agricultural cooperatives can play major roles for sustainable
                  economic development through agriculture sector in the
                  country. Its way of working is unique and the knowledge and
                  expertise on business development has proven to be successful
                  in professionalizing the cooperatives. The strategic
                  objectives of cooperative business development will be:
                  <li>
                    {" "}
                    Increase the economic added value of cooperative businesses{" "}
                  </li>
                  <li>
                    Scale up the business level of the cooperatives that are
                    already active
                  </li>
                  <li>
                    {" "}
                    Improve market access and ensure higher price for the
                    products
                  </li>
                  <br />
                  Agroroute Nepal will implement the following strategies to
                  fulfil the strategic objectives of business development:{" "}
                  <li>
                    Identifying additional ambitious and active cooperatives
                    working in agriculture, Agro-forestry and livestock areas
                    having large member base and potential for growth and can
                    make tangible impact in the lives of its members in the
                    target areas.
                  </li>
                  <li>
                    Continuing capacity building of existing and new clients to
                    scale up their businesses by ensuring quality of the
                    products through authentic certification and increased
                    access to markets.
                  </li>{" "}
                  <li>
                    Promoting value chain development concepts in cooperative
                    business to increase the economic value, which will benefit
                    its smallholder member farmers and Agro-enterprises.
                  </li>{" "}
                  <li>
                    Promoting Agro-forestry industries from farm to retail
                    through necessary business development support and
                    facilitation.
                  </li>{" "}
                  <li>
                    Proactively using the NCDPP platform for evidence-based
                    lobbying and advocacy through consultation and thematic
                    interaction events to address legal issues and facilitate
                    cooperative businesses. Agroroute Nepal’s farmers advocacy
                    consultation tool (FACT) will be employed to create
                    conducive environment for the businesses of cooperatives and
                    farmer organisations.
                  </li>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default CBD;
