import React from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

import logo from "../Media/AGROROUTE.png";

function NavBar() {
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Brand href="/">Agroroute</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Discover Agroroute">
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover">
                  About, Vision and Mision
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover2">
                  Theory of Change
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover3">
                  Key Focus Areas
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover4">
                  Key Approaches
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link as={Link} to="/discover5">
                  Target Groups
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/clientNetwork">
              Client And Networks
            </Nav.Link>

            <Nav.Link as={Link} to="/training">
              Our Product and Services
            </Nav.Link>

            <Nav.Link as={Link} to="/results">
              Results
            </Nav.Link>
            <Nav.Link as={Link} to="/allnews">
              News
            </Nav.Link>
            <Nav.Link as={Link} to="/team">
              Our Team
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
