import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";

function Discover2() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Row className=" mt-2 mb-5">
            <Col md="12" className="text-center">
              <h3 className="logo-txt-color">DISCOVER AGROROUTE</h3>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "20rem" }}>
              <Card.Body>
                <Card.Title className="text-left mx-2 logo-txt-color ">
                  THEORY OF CHANGE
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  Agroroute believes that investing in professional farmer
                  organizations, cooperatives, and small medium enterprises lead
                  to a stable and prosperous Agri-sector and economy. Ambitious
                  farmers in emerging economies want to develop themselves,
                  become entrepreneurs and business partners, create added
                  value, and move up the supply chain. Supporting these farmers
                  with training, advice and exchange creates jobs for a large
                  group of people who would rather not be farmers but want to
                  stay in rural areas. As such, farmer organizations,
                  cooperatives small medium enterprises are ideally equipped to
                  lead the gradual process of non-disruptive structural
                  transformation of rural areas.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default Discover2;
