import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";

function Discover5() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Row className=" mt-2 mb-5">
            <Col md="12" className="text-center">
              <h3 className="logo-txt-color">DISCOVER AGROROUTE</h3>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  TARGET GROUPS
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  <li>
                    Agriculture Cooperatives including primary cooperatives,
                    District cooperative unions and national level cooperative
                    federation(s)
                  </li>
                  <li>
                    Farmers organizations, including federations and unions
                  </li>
                  <li>Small Medium Enterprises</li>
                  <li>Farmers' group</li>
                  <br />
                  Through farmers’ organisations, cooperatives and SMEs, the
                  ultimate target groups of Agroroute are individual farmers,
                  agricultural entrepreneurs, women and youths.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default Discover5;
