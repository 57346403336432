import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Discover4() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Row className=" mt-2 mb-5">
            <Col md="12" className="text-center">
              <h3 className="logo-txt-color">DISCOVER AGROROUTE</h3>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  ADVISE, TRAINING AND EXCHANGE
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  Agroroute Nepal’s approach focuses on the entrepreneurial
                  ambitions of organisations and cooperatives that operate in
                  the interests of farmers. With the advisory services,
                  Agroroute Nepal wants to achieve that ‘farmer-led businesses’
                  gain access to outside capital, to enable them to invest in
                  the processing or upgrading of their products and ultimately
                  to realise a better price for their members. Agroroute Nepal
                  also wants to contribute to the strengthening of farmer
                  organizations and cooperatives by targeted lobbying and
                  advocacy, because Agroroute Nepal believes that
                  entrepreneurial farmers are more successful when they
                  collaborate in cooperatives and farmers’ organisations. Their
                  success enables them to make an important contribution to
                  employment, to the fight against poverty and to the economic
                  development of their region. As shown in the figure, Agroroute
                  Nepal uses advice, exchanges and trainings to address
                  cooperatives’ specific challenges on business development,
                  governance and financial management. The key concept of this
                  approach is that Agroroute Nepal aims at transforming
                  cooperatives into self-sustaining agri-enterprises with a
                  focus on the added value of cooperatives and organisations to
                  farmer members.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  PEER TO PEER LEARNING
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  Agroroute Nepal strongly believes that peer-to-peer learning,
                  exchanges and study tours are powerful tools for learning. As
                  a part of Agroroute Nepal advisory services Agroroute Nepal
                  provides technical field staff (business advisors),
                  peer-to-peer (farmer-to-farmer) learning, coaching tracks and
                  exchanges/study tours.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  ADVOCACY AND LOBBY
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  Agroroute Nepal strongly believes that peer-to-peer learning,
                  exchanges and study tours are powerful tools for learning. As
                  a part of Agroroute Nepal advisory services Agroroute Nepal
                  provides technical field staff (business advisors),
                  peer-to-peer (farmer-to-farmer) learning, coaching tracks and
                  exchanges/study tours.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default Discover4;
