import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/NavBar";
import { NavLink } from "react-router-dom";

import { file, url } from "../url";

function AllNews() {
  const [newsItems, setnewsItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${url}/news`);
        setnewsItems(res.data);
      } catch (err) {}
    };
    fetchData();
  }, []);
  console.log(newsItems);

  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Col className="text-center logo-txt-color" md={12}>
            <h3>All About News</h3>
          </Col>
        </Row>
        <Row>
          {newsItems.map((newsItem) => (
            <Col md="4" className=" mt-3 mb-3">
              <Card className="p-4 img-fluid">
                <Card.Img
                  height="300"
                  variant="top"
                  src={file + newsItem.thumbnail}
                />
                <Card.Body>
                  <Card.Title>{newsItem.title}</Card.Title>
                  <Card.Text>{newsItem.excerpt}</Card.Text>
                </Card.Body>
                <NavLink to={`news/${newsItem.id}`}>
                  <Button className="w-100" variant="secondary">
                    View in Detail
                  </Button>
                </NavLink>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default AllNews;
