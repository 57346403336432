import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/Navbar/NavBar";
import clients from "../Assets/Images/clients.png";
import agriterra from "../Assets/Images/agriterra.png";
import lutheran from "../Assets/Images/agro.png";
import icco from "../Assets/Images/icco.jpg";
import agro from "../Assets/Images/lutheran.png";
import seepje from "../Assets/Images/seepje.png";
import maas from "../Assets/Images/maas.png";
import dpa from "../Assets/Images/dpa.png";
import CC from "../Assets/Images/cooperative_consultancy.png";
import cordaid from "../Assets/Images/cordaid.png";

function ClientNetwork() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="mt-4 bg-light" fluid>
        <Row className="p-3">
          <Col md="12" className="text-center">
            <h3 className="logo-txt-color">CLIENTS</h3>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center p-3">
          <Col>
            <Card className=" mt-2 mb-3 p-3">
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  PRIMARY COOPERATIVES
                </Card.Title>
                <Card.Text className="text-center mt-2 mb-3 p-3 we-work">
                  {/* We work with total 15 primary agricultural cooperatives,
                district cooperative unions and national level cooperative
                federations. */}
                </Card.Text>
                {/* <Card.Img
                className=" p-3 card-img-top"
                variant="top"
                height="500"
                src={clients}
              /> */}
                <Row>
                  <Col>
                    <li>
                      <strong>
                        {" "}
                        Adhunik Agricultural Cooperative Ltd (AACL)
                      </strong>
                      <br />
                      <p className="mx-4 ">
                        -Members : 2.414 -Product: Vegetables <br /> -Turnover
                        2020: NPR 41 million
                      </p>
                    </li>
                    <li>
                      <strong>Dumarwana Milk Producer Cooperative Ltd.</strong>
                      <br />
                      <p className="mx-4">
                        -Members : 2.128
                        <br /> -Product: Dairy <br /> -Turnover 2020: NPR 292
                        million
                      </p>
                    </li>
                    <li>
                      {" "}
                      <strong>Annapurna Dairy Producer Cooperative Ltd.</strong>
                      <br />
                      <p className="mx-4">
                        -Members : 674 <br /> -Product: Dairy <br /> -Turnover
                        2020: NPR 179 million
                      </p>
                    </li>
                    <li>
                      {" "}
                      <strong>
                        Bajrabarahi Small Farmers Agricultural Cooperative Ltd
                        (BSFACL)
                      </strong>
                      <br />
                      <p className="mx-4">
                        -Members : 1.301 <br /> -Product: Potatoes <br />{" "}
                        -Turnover 2020: NPR 45 million
                      </p>
                    </li>
                  </Col>
                  <Col>
                    <li>
                      {" "}
                      <strong>
                        Dumarwana Small Farmers Agricultural Cooperative Ltd.
                        (DSFACL)
                      </strong>
                      <br />
                      <p className="mx-4 my-0">
                        -Members : : 9.784
                        <br /> -Product: Rice and other crops
                        <br /> -Turnover 2020:NPR 191 million
                      </p>
                    </li>
                    <li>
                      {" "}
                      <strong>
                        Kamdhenu Dairy Development Cooperative (KDDC)
                      </strong>
                      <br />
                      <p className="mx-4">
                        -Members : 395 <br /> -Product: Dairy <br /> -Turnover
                        2020: NPR 98 million
                      </p>
                    </li>
                    <li>
                      {" "}
                      <strong>
                        Prithivinagar Small Farmers Agricultural Cooperative
                        Ltd. (PSFACL)
                      </strong>
                      <br />
                      <p className="mx-4">
                        -Members : 2.187 <br /> -Product: Tea <br /> -Turnover
                        2020: NPR 96 million
                      </p>
                    </li>
                    <li>
                      {" "}
                      <strong>
                        Rupa Lake Restoration and Fishery Cooperative Ltd.
                        (RLRFC)
                      </strong>
                      <br />
                      <p className="mx-4 ">
                        -Members : 804 <br /> -Product: Fish <br /> -Turnover
                        2020: NPR 27 million
                      </p>
                    </li>
                    <li>
                      {" "}
                      <strong>Tinjure Tea Cooperative (TTC)</strong>
                      <br />
                      <p className="mx-4 ">
                        -Members : 254 <br /> -Product: Fish <br /> -Turnover
                        2020: NPR 8 million
                      </p>
                    </li>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center p-3">
          <Col>
            <Card className="mt-2 mb-3 p-3">
              <Card.Title className="text-left mx-3 logo-txt-color ">
                UNIONS/ALLIANCE
              </Card.Title>
              <Card.Body>
                <Card.Text className="text-center mt-2 mb-3 p-3 we-work"></Card.Text>
                <Row>
                  <Col>
                    <li>
                      <strong>Central Tea Cooperative Federation (CTCF)</strong>
                      <br />
                      <p className="mx-4">
                        -Organization Members : 10
                        <br />
                        -Famer members : 6115
                        <br />
                        -Product: Tea
                        <br />
                        -Turnover 2020 : NPR 3 million
                      </p>
                    </li>
                    <li>
                      <strong>
                        Lalitpur District Milk Producer Cooperative Union
                        (LDMPCU)
                      </strong>
                      <br />
                      <p className="mx-4">
                        -Organization Members : 54
                        <br />
                        -Famer members : 3416
                        <br />
                        -Product: Diary
                        <br />
                        -Turnover 2020 : NPR 98 million
                      </p>
                    </li>
                    <li>
                      <strong>
                        Nepal Agriculture CooperativeCentral Federation (NACCF)
                      </strong>
                      <br />
                      <p className="mx-4">
                        -Organization Members : 995
                        <br />
                        -Famer members : 970.000
                        <br />
                        -Product: Different crops
                        <br />
                        -Turnover 2020 : NPR 66 million
                      </p>
                    </li>
                  </Col>
                  <Col>
                    <li>
                      <strong>
                        District Agriculture Cooperative Union (DACU), Makwanpur
                      </strong>
                      <br />
                      <p className="mx-4">
                        -Organization Members : 51
                        <br />
                        -Famer members : 25.100
                        <br />
                        -Product: Different crops
                        <br />
                        -Turnover 2020 : NPR 2 million
                      </p>
                    </li>
                    <li>
                      <strong>
                        Sindhupalchow Coffee Cooperative Union (SCCU)
                      </strong>
                      <br />
                      <p className="mx-4">
                        -Organization Members : 10
                        <br />
                        -Famer members : 700
                        <br />
                        -Product: Coffee
                        <br />
                        -Turnover 2020 : NPR 6 million
                      </p>
                    </li>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5 bg-light" fluid>
        <Row className="p-3">
          <Col md="12" className="text-center">
            <h3 className="logo-txt-color">NETWORKS</h3>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center p-3">
          <Col md="3">
            <Card className=" mt-2 mb-3 p-3 img-fluid">
              <Card.Body>
                <Card.Img variant="top" height="100" src={agriterra} />
              </Card.Body>
            </Card>
          </Col>
          <Col md="3">
            <Card className=" mt-2 mb-3 p-3 img-fluid">
              <Card.Body>
                <Card.Img height="100" variant="top" src={lutheran} />
              </Card.Body>
            </Card>
          </Col>

          <Col md="3">
            <Card className=" mt-2 mb-3 p-3 ">
              <Card.Body>
                <Card.Img variant="top" height="100" src={agro} />
              </Card.Body>
            </Card>
          </Col>
          <Col md="3">
            <Card className=" mt-2 mb-3 p-3 img-fluid">
              <Card.Body>
                <Card.Img height="100" variant="top" src={seepje} />
              </Card.Body>
            </Card>
          </Col>
          <Col md="3">
            <Card className=" mt-2 mb-3 p-3">
              <Card.Body>
                <Card.Img height="100" variant="top" src={dpa} />
              </Card.Body>
            </Card>
          </Col>
          <Col md="3">
            <Card className=" mt-2 mb-3 p-3">
              <Card.Body>
                <Card.Img height="100" variant="top" src={cordaid} />
              </Card.Body>
            </Card>
          </Col>
          <Col md="3">
            <Card className=" mt-2 mb-3 p-3">
              <Card.Body>
                <Card.Img height="100" variant="top" src={CC} />
              </Card.Body>
            </Card>
          </Col>
          <Col md="3">
            <Card className=" mt-2 mb-3 p-3">
              <Card.Body>
                <Card.Img height="100" variant="top" src={maas} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default ClientNetwork;
