import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import NavBar from "../Components/Navbar/NavBar";
import sachin from "../Assets/demo.mp4";
import demo2 from "../Assets/demo2.mp4";
import demo3 from "../Assets/demo3.mp4";
import { useHistory, useParams } from "react-router-dom";
import { file, url } from "../url";

function NewsPage() {
  let videoArray = [];

  videoArray.push(sachin);
  videoArray.push(demo2);
  videoArray.push(demo3);

  const [newsItems, setnewsItems] = useState([]);
  const { id } = useParams();
  console.log(id);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${url}/news/${id}/`);
        setnewsItems(res.data);
        console.log(newsItems);
      } catch (err) {}
    };
    fetchData();
  }, []);
  return (
    <Container>
      <NavBar />
      <Row className="justify-content-md-center">
        <Col md="" className="pt-4">
          <h1>{newsItems.title}</h1>
          <p>{newsItems.excerpt}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card border="secondary">
            <Card.Body>By {newsItems.uploader} | Agroroute Nepal</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="p-4">
        <Container fluid className="">
          <Card.Img
            height="500"
            variant="top"
            src={file + newsItems.thumbnail}
          />
        </Container>
      </Row>
      <Row>
        <Col md="12" className="mt-1"></Col>
        <Col>
          <Card border="secondary">
            <Card.Body>
              {newsItems.day}-{newsItems.month} | Agroroute Nepal
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col
          md="auto"
          className="p-5"
          dangerouslySetInnerHTML={{ __html: newsItems.content }}
        ></Col>
      </Row>
    </Container>
  );
}

export default NewsPage;
