import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";

function SPP() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Row className=" mt-2 mb-5">
            <Col md="12" className="text-center">
              <h3 className="logo-txt-color">DISCOVER AGROROUTE</h3>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-2 logo-txt-color ">
                  SUSTAINABLE PRODUCTION AND PRODUCTIVITY
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  Rural poverty and agricultural productivity are closely linked
                  in Nepal given the dominant role of agriculture in the
                  economy. Sustainable production and productivity are crucial
                  (with a view on climate change) areas for the growth of
                  cooperatives engaged in agri-businesses. The strategic
                  objectives of sustainable production and productivity will be:
                  <li>Adopt efficient farming technologies</li>{" "}
                  <li>Establish cooperative partnership</li>{" "}
                  <li>
                    Promote measures to mitigate or adapt to the effects of
                    climate change
                  </li>{" "}
                  <br />
                  Agroroute Nepal will implement the following strategies to
                  fulfil the strategic objectives of sustainable production and
                  productivity:
                  <li>
                    Assisting in adopting appropriate technologies in farming
                    and product processing for clients through Agripool experts,
                    in order to enhance increased production and productivity.
                  </li>
                  <li>
                    Facilitating partnership among cooperatives for
                    collaboration and exchange of their experiences and
                    expertise with regard to production and productivity. •
                    Exploring and providing support to cooperatives for
                    practical adaptation measures that ensure sustainable
                    production and productivity.
                  </li>
                  <li>
                    Building and strengthening networks of relevant clients to
                    work on common issues and interests for their growth and
                    sustainability.
                  </li>{" "}
                  <li>
                    Sharing of Agroroute Nepal's best practice cases with other
                    organisations.
                  </li>{" "}
                  <li>
                    Creating an environment and a mechanism for using and
                    expanding the pool of Agripool experts (locally) in Nepal.
                  </li>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default SPP;
