import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row, ListGroup } from "react-bootstrap";

function Training() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Col className="text-center logo-txt-color" md={12}>
            <h3>OUR PRODUCTS AND SERVICES</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  INSTITUTIONAL CAPACITY AND DEVELOPMENT
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  <li>Managing your Agriculture Cooperatives</li>
                  <li>Aspire</li>
                  <li>Strategic planning</li>
                  <li>Farmer advocacy consultation tools</li>
                  <li>Scoping/Cooperatives assessment</li>
                  <li>Proposal writing</li>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  GOVERNANCE AND HRM
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  <li>Cooperative governance</li>
                  <li>Leadership and people management training</li>
                  <li>Account supervisory board training</li>
                  <li>Member commitment workshop</li>
                  <li>Female leadership</li>
                  <li>Youth workshop</li>
                  <li>Members commitment</li>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  FINANCIAL MANAGEMENT
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  <li>Financial management for agriculture cooperatives</li>
                  <li>
                    Financial management for saving and credit cooperatives
                  </li>
                  <li>Strategic financial management</li>
                  <li>Account and taxation</li>
                  <li>Microfinance Training of Trainers</li>
                  <li>Internal Capitalization</li>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  BUSINESS DEVELOPMENT
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  <li>Basic Marketing Training</li>
                  <li>Agi coop marketing training</li>
                  <li>Sustainable service workshop</li>
                  <li>Value creator</li>
                  <li>Record keeping</li>
                  <li>Business planning</li>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* <Row className="">
          <Col className="" md={{ span: 6, offset: 3 }}>
            <Card className="my-2 p-1 rounded">
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  TRAINING COOPERATIVES
                </Card.Title>
                <Card.Text className="text-left mx-2 mt-2 mb-3 p-1">
                  Agroroute has standardized training products that are
                  developed and used by Agriterra. Agroroute had an MoU with
                  Agriterra to use the training products of it.
                </Card.Text>

                <Card.Header>
                  <h4 className="logo-txt-color1">We provide trainings on:</h4>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>Management and organisation</ListGroup.Item>
                  <ListGroup.Item>Governance and leadership</ListGroup.Item>
                  <ListGroup.Item>Financial management</ListGroup.Item>
                  <ListGroup.Item>Business development</ListGroup.Item>
                  <ListGroup.Item>Lobby and advocacy</ListGroup.Item>
                  <ListGroup.Item>
                    For more information, please visit{" "}
                    <a
                      className="teamdata"
                      target="_blank"
                      href="https://training.agriterra.org/training"
                    >
                      Agriterra
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </Container>
      <Footer />
    </Container>
  );
}

export default Training;
