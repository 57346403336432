import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";

function Discover() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Row className=" mt-2 mb-5">
            <Col md="12" className="text-center">
              <h3 className="logo-txt-color">DISCOVER AGROROUTE</h3>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "20rem" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 logo-txt-color ">
                  ABOUT AGROROUTE
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  Agroroute Nepal Pvt Ltd. has been established to strengthen
                  Agri-cooperatives, farmer organizations, SMEs and other
                  organizations in agribusiness under the slogan “Accelerating
                  Agribusiness”. Agroroute Nepal follows the footsteps of the
                  work of Agriterra in Nepal. Agriterra Nepal has since the
                  start of activities in Nepal in 2001 gained a strong
                  reputation in the field of strengthening farmer organizations
                  in Nepal. Due to a shift in available subsidies from external
                  donors, the former staff members of Agriterra Nepal found a
                  modality to continue strengthening farmer organizations by
                  establishing their own company Agroroute; and linking it to
                  the globally active organization of Agriterra via a Memorandum
                  of Understanding. Agroroute Nepal sees it as an opportunity to
                  support, collaborate and coordinate with a wider range of
                  stakeholders towards strengthening the governance, financial
                  management, agri-business and institutional development of
                  farmer organizations, cooperatives and SMEs through training
                  and advice. Through the Memorandum of Understanding between
                  Agriterra and Agroroute Nepal, Agroroute can guarantee the
                  quality of services provided, with access to Agriterra’ s best
                  practices.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "20rem" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3  logo-txt-color ">
                  VISION
                </Card.Title>
                <Card.Text className="text-left custom-font-19  mt-2 mb-3 p-3">
                  Professional and competitive farmer organizations in Nepal
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "20rem" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3  logo-txt-color">
                  MISSION
                </Card.Title>
                <Card.Text className="text-left custom-font-19  mt-2 mb-3 p-3">
                  Providing high quality, demand driven and customized capacity
                  building services, building upon the collective strength of
                  farmer organizations that are vital in triggering economic
                  development in rural areas.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "20rem" }}>
              <Card.Body>
                <Card.Title className="text-left mx-3 custom-font-19 logo-txt-color">
                  FOCUS
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  Farmer Organization Business Development, Sustainable
                  Production and Productivity, Organisational Capacity of Farmer
                  Organization
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}
export default Discover;
