import React from "react";
import NavBar from "../Components/Navbar/NavBar";
import Footer from "../Components/Footer/Footer";
import { Card, Col, Container, Row } from "react-bootstrap";

function OCC() {
  return (
    <Container fluid>
      <NavBar />
      <Container className="my-4 p-3 bg-light" fluid>
        <Row className="p-3">
          <Row className=" mt-2 mb-5">
            <Col md="12" className="text-center">
              <h3 className="logo-txt-color">DISCOVER AGROROUTE</h3>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <Card className=" mt-2 mb-3 p-3" style={{ height: "auto" }}>
              <Card.Body>
                <Card.Title className="text-left mx-2 logo-txt-color ">
                  ORGANISATIONAL CAPACITY OF COOPERATIVES
                </Card.Title>
                <Card.Text className="text-left custom-font-19 mt-2 mb-3 p-3">
                  Agriculture cooperatives often face similar challenges:
                  ineffective management, lack of market access, inadequate
                  member commitment, and insufficient services including
                  financial services, among others. Agricultural cooperatives
                  require a robust organisational capacity to operate their
                  businesses professionally, effectively and efficiently.
                  Agroroute Nepal has a set of proven and standardized tools for
                  organisational capacity building of cooperatives that enables
                  them to work in professional way. The strategic objectives for
                  organisational capacity of cooperatives will be:
                  <li>
                    Support for strengthening organizational capacity for
                    economic development of farmers
                  </li>{" "}
                  <li>Systematize internal system and structure </li>{" "}
                  <li>Strengthen governance and leadership</li> <br />
                  Agroroute Nepal will implement the following strategies to
                  fulfil the strategic objectives of organisational capacity of
                  cooperatives:
                  <li>
                    Ensuring all the clients have strong, democratic and
                    transparent governance and leadership who can effectively
                    and efficiently run cooperative businesses and serve their
                    member farmer entrepreneurs.
                  </li>
                  <li>
                    Supporting and ensuring that all the clients have properly
                    controlled financial management systems.
                  </li>
                  <li>
                    Promoting management information and ICT systems for
                    improved information provision to farmers
                  </li>{" "}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}

export default OCC;
