import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Container, Row } from "react-bootstrap";
import { file, url } from "../../url";

function Gallery() {
  const [galleryItems, setgalleryItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${url}/photos`);
        setgalleryItems(res.data);
      } catch (err) {}
    };
    fetchData();
  }, []);
  console.log(galleryItems);
  return (
    <Container className="mt-5 bg-light" fluid>
      <Row className="p-3">
        <Row>
          <Col md="12" className="text-center">
            <h3 className="logo-txt-color">GALLERY</h3>
          </Col>
        </Row>
        <Row>
          {galleryItems.map((galleryItem) => (
            <Col md="4">
              <Row className="p-3 mt-3 mb-3">
                <Card className="p-4 img-fluid rounded">
                  <Card.Img
                    height="300"
                    variant="top"
                    src={file + galleryItem.image}
                  />
                </Card>
              </Row>
            </Col>
          ))}
        </Row>
      </Row>
    </Container>
  );
}

export default Gallery;
